<template>
  <div>
    <div class="player">
      <aside class="player__aside">
        <div class="player-box">
          <ul class="artist-list">
            <li v-for="(item, index) in sortedArray" :key="index" @click="getAlbums(item._id)">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </aside>
      <div class="player__content">
        <div class="player-box">
          <YoutubeVue3 ref="youtube" :videoid="video_id" :loop="1" :autoplay="1"  :height="150" @ended="onEnded" :controls="1"/>
        </div>
        <div :class="'player-box player-box--album-box ' + classContent">
          <ul class="album-list">
            <li v-for="(item, index) in selectAlbum" :key="index">
              <span class="album-list__title">{{ item.name }}</span>
              <ul>
                  <li v-for="(song, indexsong) in item.songs" :key="indexsong" @click="playSong(song.id_youtube)">{{ song.name }}</li>
                </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="player-bar">
      <button class="player-button" @click="playPlayer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
      </button> <button class="player-button" @click="pausePlayer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/></svg>
      </button> 
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { YoutubeVue3 } from 'youtube-vue3'
import axios from 'axios'

export default {
  name: 'Home',
  data() {
    return {
      artists: [],
      selectAlbum: [],
      video_id: "",
      classContent: '',
      playerState: '',
    }
  },
  components: {
    YoutubeVue3,
  },
  computed: {
    sortedArray() {
      return this.artists.sort((a, b) => {
          return a.name.localeCompare(b.name);
      });
    },
  },
  mounted() {
    this.getArtists();
    this.getAllAlbum();
  },
  watch: {
    selectAlbum(newQuestion, oldQuestion) {
      this.getArtists();
    },
  },
  methods: {
    async getArtists() {
      axios
      .get('https://api3.luk-dev.usermd.net/api/collections/get/player_artists', {
      headers: {
          'Content-Type': 'application/json',
          'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
        }
      })
      .then((response) => {
        this.artists = response.data.entries
      })
    },
    async getAllAlbum() {
      const th = this;
      const staticId = '64adc9f88ae4bc2c1502f2d2';
      try {
        axios
        .get('https://api3.luk-dev.usermd.net/api/collections/get/player_group?filter[artist]=' + staticId, {
          headers: {
            'Content-Type': 'application/json',
            'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
          },
        })
        .then((response) => {
          const arrstatic = response.data.entries;
          if (response.status == 200) {
            arrstatic.forEach(function (item, index) {
              axios
              .get('https://api3.luk-dev.usermd.net/api/collections/get/playerids?filter[group]=' + item.custom_id, {
                headers: {
                  'Content-Type': 'application/json',
                  'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
                },
              })
              .then((response) => {
                th.classContent = 'is-all-albums';
                item.songs = response.data.entries
                th.selectAlbum = arrstatic
              })
            });
          }
        })
      } catch (error) {

      }

    },
    async getAlbums(id) {
      const th = this;
      try {
        axios
        .get('https://api3.luk-dev.usermd.net/api/collections/get/player_group?filter[artist]=' + id, {
          headers: {
            'Content-Type': 'application/json',
            'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
          },
        })
        .then((response) => {
          const arr = response.data.entries;
          if (response.status == 200) {
            arr.forEach(function (item, index) {
              axios
              .get('https://api3.luk-dev.usermd.net/api/collections/get/playerids?filter[group]=' + item.custom_id, {
                headers: {
                  'Content-Type': 'application/json',
                  'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
                },
              })
              .then((response) => {
                item.songs = response.data.entries
                th.selectAlbum = arr
                if (item.custom_id == 1 || item.custom_id == 5) {
                  th.classContent = 'is-all-albums';
                } else {
                  th.classContent = '';
                }
              })
            });
          }
        })
      } catch (error) {

      }

    },
    playCurrentVideo() {
      this.$refs.youtube.player.setPlaybackQuality('small');
      this.$refs.youtube.player.playVideo();
    },
    getSongs(item, id) {
      const th = this;
    },
    playSong(id) {
      this.video_id = id;
      this.$refs.youtube.player.setPlaybackQuality('small');
      // this.$refs.youtube.player.playVideo();
    },
    onEnded() {
      this.playCurrentVideo();
    },
    playPlayer() {
      this.$refs.youtube.player.setPlaybackQuality('small');
      this.$refs.youtube.player.playVideo();
    },
    pausePlayer() {
      this.$refs.youtube.player.setPlaybackQuality('small');
      this.$refs.youtube.player.pauseVideo();
    }
  },
}
</script>