<template>
  <div>
    <router-view />
    <!-- <Bar /> -->
  </div>
</template>

<script>
// import Bar from "./components/Bar.vue";

export default {
  name: 'App',
  components: {
    // Bar,
  },
}
</script>