<template>
  <div>
    <h1>manage</h1>
    <div style="display: flex;">
      <div class="player-box manage-wr">
        <div class="manage-col">
        <h3>Artyści</h3>
        <ul class="">
          <li v-for="(item, index) in artists" :key="index">
            {{ item.name }} | <button @click="this.getArtist(item._id)">Edytuj</button>
          </li>
        </ul>
        </div>
        <div class="manage-col">
          <h3>Add/Edit Artysta</h3>
            <form>
              <div class="field">
                <label class="label">Nazwa</label>
                <div class="control">
                  <input class="input" type="text" v-model="formArtist.name" />
                </div>
              </div>
              <button class="btn" @click.prevent="updateArtist(this.formArtist.id)" type="submit">Update</button>
            </form>
        </div>
      </div>
      <div class="player-box manage-wr">
        <div class="manage-col">
          <h3>Albumy</h3>
          <ul class="">
            <li v-for="(item, index) in albums" :key="index">
              {{ item.name }} | {{ item.custom_id }} |<button @click="this.getAlbum(item._id)">Edytuj</button>
            </li>
          </ul>
        </div>
        <div class="manage-col">
          <h3>Add/Edit Album</h3>
            <form>
              <div class="field">
                <label class="label">name</label>
                <div class="control">
                  <input class="input" type="text" v-model="formAlbum.name" />
                </div>
              </div>
              <div class="field">
                <label class="label">custom_id</label>
                <div class="control">
                  <input class="input" type="text" v-model="formAlbum.custom_id" />
                </div>
              </div>
              <div class="field">
                <label class="label">artist {{ formAlbum.artist }}</label>
                <div class="control">
                  <select v-model="formAlbum.artist">
                    <option v-for="artist in artists" :key="artist._id" :value="artist._id">
                      {{ artist.name }}
                    </option>
                  </select>
                </div>
              </div>
              <button class="btn" @click.prevent="updateAlbum(this.formAlbum.id)" type="submit">Update</button>
              <button class="btn" @click.prevent="clearFormAlbum()" type="submit">Clear form</button>
            </form>
        </div>
      </div>
      <div class="player-box manage-wr">
        <div class="manage-col">
          <h3>Playlisty</h3>
          <ul class="">
            <li v-for="(item, index) in playlists" :key="index">
              {{ item.name }} | {{ item.custom_id }} |<button @click="this.getAlbum(item._id)">Edytuj</button>
            </li>
          </ul>
        </div>
        <div class="manage-col">
          <h3>Add/Edit Playlist</h3>
            <form>
              <div class="field">
                <label class="label">name</label>
                <div class="control">
                  <input class="input" type="text" v-model="formAlbum.name" />
                </div>
              </div>
              <div class="field">
                <label class="label">custom_id</label>
                <div class="control">
                  <input class="input" type="text" v-model="formAlbum.custom_id" />
                </div>
              </div>
              <div class="field">
                <label class="label">artist {{ formAlbum.artist }}</label>
                <div class="control">
                  <select v-model="formAlbum.artist">
                    <option v-for="artist in artists" :key="artist._id" :value="artist._id">
                      {{ artist.name }}
                    </option>
                  </select>
                </div>
              </div>
              <button class="btn" @click.prevent="updateAlbum(this.formAlbum.id)" type="submit">Update</button>
              <button class="btn" @click.prevent="clearFormAlbum()" type="submit">Clear form</button>
            </form>
        </div>
      </div>
    </div>

  </div>
  <!-- <div v-if="!page_disable">
    <input type="text" name="passwordpage" v-model="password">
    <button @click="this.checkPass()">Wejdz</button>
  </div> -->

  <!-- <div v-if="page_disable">
    tu jest content
  </div> -->
</template>

<script>
/* eslint-disable */
import axios from 'axios'

export default {
  name: 'Manage',
  data() {
    return {
      password: '',
      page_disable: false,
      artists: [],
      albums: [],
      playlists: [],
      formArtist: {
        id: "",
        name: "",
      },
      formAlbum: {
        id: "",
        name: "",
        custom_id: "",
        artist: "",
      }
    }
  },
  mounted() {
    this.getArtists();
    this.getAlbums();
  },
  methods: {
    checkPass() {
      if(this.password === '1234') {
        this.page_disable = true
      }
    },
    getArtists() {
      axios
      .get('https://api3.luk-dev.usermd.net/api/collections/get/player_artists', {
      headers: {
          'Content-Type': 'application/json',
          'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
        }
      })
      .then((response) => {
        this.artists = response.data.entries
      })
    },
    getAlbums() {
      axios
      .get('https://api3.luk-dev.usermd.net/api/collections/get/player_group', {
      headers: {
          'Content-Type': 'application/json',
          'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
        }
      })
      .then((response) => {
        this.albums = response.data.entries
        console.log(response.data.entries)
      })
    },
    getAlbum(id) {
      axios({
          url: "https://api3.luk-dev.usermd.net/api/collections/get/player_group",
          data: { 
            "filter": {"_id": id},
            "limit": 1,
          },
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
          }
        })
        .then((item) => {
          this.formAlbum.id = item.data.entries[0]._id;
          this.formAlbum.name = item.data.entries[0].name
          this.formAlbum.custom_id = item.data.entries[0].custom_id
          this.formAlbum.artist = item.data.entries[0].artist
        })
        .catch((e) => {
        });
    },
    getArtist(id) {
      axios({
          url: "https://api3.luk-dev.usermd.net/api/collections/get/player_artists",
          data: { 
            "filter": {"_id": id},
            "limit": 1,
          },
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
          }
        })
        .then((item) => {
          this.formArtist.id = item.data.entries[0]._id;
          this.formArtist.name = item.data.entries[0].name
        })
        .catch((e) => {
          this.errors_notify.push(e);
        });
    },
    clearFormAlbum () {
      this.formAlbum.id = "";
      this.formAlbum.name =  "";
      this.formAlbum.custom_id =  "";
      this.formAlbum.artist =  "";
    },
    updateAlbum(id) {
      let dataAlbum = {}
      if (id) {
        dataAlbum = {"data": {
              "name": this.formAlbum.name,
              "custom_id": this.formAlbum.custom_id,
              "artist": this.formAlbum.artist,
              "_id": id
            }
        }
      } else {
        dataAlbum = {"data": {
              "name": this.formAlbum.name,
              "custom_id": this.formAlbum.custom_id,
              "artist": this.formAlbum.artist,
            }
        }
      }
      axios({
          url: "https://api3.luk-dev.usermd.net/api/collections/save/player_group",
          data: dataAlbum,
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
          }
        })
        .then(() => {
          this.formAlbum.id = "";
          this.formAlbum.name =  "";
          this.formAlbum.custom_id =  "";
          this.formAlbum.artist =  "";
          this.getAlbums();
        })
    },
    updateArtist(id) {
      let dataArtist = {}
      if (id) {
        dataArtist = {"data": {
            "name": this.formArtist.name,
            "_id": id
          }
        }
      } else {
        dataArtist = {"data": {
            "name": this.formArtist.name,
          }
        }
      }
      axios({
          url: "https://api3.luk-dev.usermd.net/api/collections/save/player_artists",
          data: dataArtist,
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Cockpit-Token': 'b8ec00777307ed2856f4e59630b2d7'
          }
        })
        .then(() => {
          this.formArtist.id = "";
          this.formArtist.name =  "";
          this.getArtists();
        })
    },
  },
}
</script>